@import "@abw/badger-ui/dist/styles/badger-ui.scss";
@import 'react-toastify/dist/ReactToastify.css';
$line-color: #eee;

.wordley {
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 1rem 1rem 1rem;
  .info-toggle {
    font-size: $size-larger;
    &.active {
      color: $orange;
    }
    &:hover {
      color: $blue;
    }
    transition: color 0.25s;
  }
  .author {
    font-size: $size-medium;
    margin-bottom: 0rem;
  }
  .version {
    font-size: $size-small;
  }
  .about {
    font-size: $size-largish;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .help {
    margin-top: 0.5rem;
    font-size: $size-smallish;
  }
  .summary {
    font-size: $size-smallish;
  }
  .clickable {
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
  .words {
    margin-top: 0;
    font-size: $size-largish;
    line-height: 1.4;
    list-style: none;
    padding: 0;
    columns: 3;
    li {
      columns: 1;
      &.selected {
        font-weight: bold;
      }
      &.error {
        color: $red;
      }
    }
  }
  .paging {
    border-top: 1px solid $line-color;
    padding-top: 8px;
    .button {
      margin-bottom: 0;
    }
  }
  .dictionary {
    .word {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: bold;
      border-bottom: 1px solid $line-color;
      padding-bottom: 0.25rem;
      .icon.clear:hover {
        color: $red;
        cursor: pointer;
      }
    }
    &.error .word .text {
      color: $red;
    }
    .error {
      font-size: $size-smallish;
      color: $red;
      font-weight: normal;
    }
    .results {
      font-weight: normal;
    }
    .result {
      border-bottom: 1px solid $line-color;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      &:last-child {
        border-bottom: none;
      }
      .meaning {
        margin-bottom: 1rem;
        //padding-left: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h4 {
        margin-bottom: 0;
      }
      .part-of-speech {
        color: #888;
        font-style: italic;
      }
      .definitions {
        padding-left: 0.5rem;
      }
      .definition {
        padding-left: 2rem;
        margin-bottom: 0.5rem;
        .n {
          display: inline-block;
          margin-left: -2rem;
          width: 2rem;
          color: #999;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .synonyms {
        padding-left: 2.5rem;
        font-size: $size-smallish;
        h4 {
          font-size: $size-smallish;
          font-weight: bold;
          color: $grey;
        }
        .synonym-results {
          padding-left: 1rem;
        }
        .synonym {
          font-style: italic;
        }
      }
    }
  }
  footer {
    text-align: center;
    min-height: 160px;
    margin-top: 2rem;
    // border-top: 1px solid $line-color;
    padding-top: 0.5rem;
    .copyright {
      font-size: $size-small;
      color: #888;
    }
    .tagline {
      font-size: $size-small;
      color: #aaa;
    }
    svg {
      width: 100px;
      margin-bottom: 1rem;
    }
  }
}

kbd {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  border-radius: 0.25rem;
  //font-size: 0.75em;
  line-height: 1;
  padding: 2px 5px;
  text-align: center;
  min-width: 0.75rem;
}

@media screen and (max-width: 30rem) {
  .wordley .words {
    columns: 2;
  }
}

@media screen and (max-width: 16rem) {
  .wordley .words {
    columns: 1;
  }
}